.welcome-section {
  background-color: #fdfdfd;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.welcome-content {
  display: flex;
  align-items: center;
}

.welcome-text {
  flex: 1;
  margin-left: 30px;
  text-align: justify;
  text-justify: inter-word;
}

.welcome-text h1 {
  font-size: 26px;
  color: #000040;
  text-align: center;
}

.welcome-text p {
  font-size: 15px;
  line-height: 1.6;
  color: #4a4a4a;
  text-align: justify;
  text-justify: inter-word;
}

.welcome-buttons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.start-training-btn,
.submit-ticket-btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s;
}

.start-training-btn {
  background-color: #00c2f1;
  color: white;
}

.submit-ticket-btn {
  background-color: #f56b6b;
  color: white;
}

.start-training-btn:hover {
  background-color: #009fcb;
}

.submit-ticket-btn:hover {
  background-color: #d94545;
}

.welcome-image {
  background-color: transparent;
}

.welcome-image img {
  max-width: 320px;
  height: auto;
  background-color: transparent;
}

.support-info {
  padding: 16px;
  margin-bottom: 20px;
}

.title-support {
  font-size: 20px;
  margin-bottom: 10px;
  color: #000040;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.support-details {
  display: flex;
  justify-content: space-between;
}

.support-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #4a4a4a;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-right: 10px;
}

.support-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.quick-start {
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 380px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.quick-start-header {
  background-color: #000040;
  color: white;
  padding: 10px;
  border-radius: 12px 12px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.quick-start-title {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-top: 3px;
}

.quick-start-content {
  background-color: #f8f9fa;
  border-radius: 0 0 12px 12px;
  overflow-y: auto;
  flex-grow: 1;
}

.quick-start-container {
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.quick-start-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.quick-start-item:hover {
  transform: translateY(-4px);
}

.quick-start-item h3 {
  color: #e63946;
  margin-bottom: 10px;
  font-size: 18px;
}

.quick-start-item p {
  color: #1d1d1b;
  font-size: 13px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.quick-start-button {
  background-color: #e63946;
  color: #ffffff;
  border: none;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  margin-top: auto;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.quick-start-button:hover {
  background-color: #c32d3b;
}
