.lifetime-container {
  display: flex;
  font-family: "Arial", sans-serif;
  gap: 20px;
  margin: 50px;
}

.lifetime-container > :first-child {
  flex-basis: 40%;
}

.lifetime-container > :nth-child(2) {
  flex-basis: 60%;
  padding: 30px;
}

@media (max-width: 1024px) {
  .lifetime-container {
    flex-direction: column;
  }

  .lifetime-container > :first-child,
  .lifetime-container > :nth-child(2) {
    flex-basis: 100%;
    padding: 20px;
  }

  .lifetime-container > :nth-child(2) {
    padding-top: 0;
  }
}
