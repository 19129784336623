.bot-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
  color: #333;
}

.bot-sidebar {
  width: 30%;
  padding: 20px;
}

.bot-info {
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
}

.bot-header {
  background-color: #dbeaff;
  padding: 12px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
}

.bot-title {
  font-size: 28px;
  font-weight: bold;
  color: #002060;
  text-align: left;
  margin: 0;
}

.bot-id {
  font-size: 15px;
  color: #333;
  text-align: right;
  font-style: italic;
  margin: 0;
  text-decoration: underline;
}

.bots-detailss {
  background-color: #ffffff;
  padding: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bot-detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px; /* Adds space between items */
  border-bottom: 1px solid #e7e7e7; /* Adds the underline */
}

.bot-detail-item:last-child {
  border-bottom: none; /* Removes underline from the last item */
}

.botdetail-title {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
  min-width: 150px;
}

.botdetail-info {
  flex-grow: 1;
  text-align: left;
}

.bot-detail-item .icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.bot-detail-item .icon img {
  width: 100%;
  height: 100%;
}

.detail-text-title {
  font-size: 16px;
  color: #ff0057;
  font-weight: bold;
  margin-right: 3px;
}

.detail-text {
  font-size: 16px;
  color: #333;
  font-weight: normal;
  margin-left: 3px;
}

.support-info {
  margin-top: 10px;
  padding: 15px;
}

.support-text {
  font-size: 18px;
  font-weight: bold;
  color: #002060;
  margin-bottom: 15px;
}

.support-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 8px;
  border-radius: 15px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.contact-details {
  display: flex;
  align-items: center;
}

.support-contact .icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.support-contact .icon img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.contact-text {
  font-size: 14px;
  color: #002060;
  font-weight: bold;
}

.copysupport-icon {
  cursor: pointer;
  color: #002060;
  font-size: 16px;
}

.bot-list-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.bot-list-header {
  margin-bottom: 15px;
}

.bot-list-title {
  font-size: 24px;
  font-weight: bold;
  color: #002060;
  margin: 0;
}

.bot-list {
  display: flex;
  flex-direction: column;
}

.bot-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #f4f6fc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.bot-list-item:hover {
  background-color: #dbeaff;
}

.bot-list-item.selected {
  background-color: #dbeaff;
}

.bot-list-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #002060;
  border-radius: 50%;
  margin-right: 15px;
}

.bot-list-icon img {
  width: 24px;
  height: 24px;
}

.bot-list-info {
  flex-grow: 1;
}

.bot-list-name {
  font-size: 18px;
  font-weight: bold;
  color: #002060;
  margin: 0;
}

.bot-list-status {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.bot-list-status.active {
  color: #28a745;
}

.bot-list-status.cancel {
  color: #dc3545;
}

.bot-list-status.inprogress {
  color: #e67e22;
}

.bot-chat {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  max-height: 750px;
}

.chat-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #c0c8e2;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  background-color: #212d6f;
  padding: 15px;
  border-radius: 20px 20px 0 0;
}

.chat-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.chat-messages {
  background-color: #e7e7e7;
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.bot-message {
  justify-content: flex-start;
}

.user-message {
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.message-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.bot-iconsz {
  background-color: #002060;
}

.message-icon img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.message-text {
  max-width: 65%;
  padding: 12px 18px;
  background-color: #404040;
  border-radius: 20px;
  font-size: 16px;
  color: #f4f4f4;
}

.user-message .message-text {
  background-color: #bcdcff;
  color: #002060;
}

.chat-input {
  display: flex;
  align-items: center;
  border-top: 1px solid #c0c8e2;
  background-color: #212d6f;
  border-radius: 0 0 20px 20px;
  padding: 10px;
}

.input-message {
  flex: 20;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  padding: 10px;
  outline: none;
  border-radius: 25px;
}

.input-message::placeholder {
  color: #aaa;
}

.send-button {
  flex: 40px;
  background-color: transparent;
  border: none;
  color: #ff5f77;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;
}

.send-button:hover {
  color: #ff7692;
}

.chat-messages::-webkit-scrollbar {
  width: 10px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #ff5f77;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 10px;
}

.chat-messages {
  scrollbar-width: thin;
  scrollbar-color: #ff5f77 #e7e7e7;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #ff7692;
}
