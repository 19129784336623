.editformpayment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.editformpayment-modal-content {
  background-color: white; /* Changed to white */
  padding: 30px; /* Increased padding for better spacing */
  border-radius: 10px;
  max-width: 800px; /* Increased width */
  width: 100%;
  max-height: 90vh; /* Ensure it doesn't exceed viewport height */
  overflow-y: auto; /* Enable scrolling if content is too tall */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Added shadow for a modern look */
  position: relative;
}

.editformpayment-edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.editformpayment-bot-section {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.editformpayment-bot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editformpayment-remove-bot-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.editformpayment-remove-bot-btn:hover {
  background-color: #c82333;
}

.editformpayment-add-bot-btn {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-top: 15px;
}

.editformpayment-add-bot-btn:hover {
  background-color: #218838;
}

.editformpayment-form-group label {
  font-weight: bold;
}

.editformpayment-form-group select,
.editformpayment-form-group input,
.editformpayment-form-group textarea {
  width: 100%;
  padding: 12px; /* Increased padding for better readability */
  font-size: 16px; /* Increased font size */
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white; /* Ensures input fields have a clean white background */
}

.editformpayment-uploaded-files .editformpayment-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.editformpayment-uploaded-files .editformpayment-file-item button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.editformpayment-uploaded-files .editformpayment-file-item button:hover {
  background-color: #c82333;
}

.editformpayment-form-actions {
  display: flex;
  justify-content: space-between;
}

.editformpayment-form-actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.editformpayment-form-actions button:hover {
  background-color: #0056b3;
}
