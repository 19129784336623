.lifetime-details-container {
  width: 550px; /* Decreased width */
  max-height: 800px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lifetime-details-header {
  background-color: #001f69;
  color: white;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  text-align: center;
}

.lifetime-details-header h2 {
  margin: 0;
  font-size: 20px;
}

.lifetime-details-section {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.section-content {
  display: flex;
  align-items: flex-start;
  color: #333;
  font-size: 15px;
  text-align: start;
  line-height: 1.5;
}

.bullet-point {
  margin-right: 10px;
  font-weight: bold;
  display: inline-block;
  width: 10px;
  flex-shrink: 0;
}

.highlighted-cost {
  color: #df3f68; /* The color shown in the image */
  font-weight: bold; /* Make it bold */
}

.section-text {
  flex-grow: 1; /* Make the text take up the remaining space */
  line-height: 1.5; /* Improve readability */
}

.section-title {
  color: #df3f68;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.lifetime-details-footer {
  padding: 15px;
  text-align: center;
}

.footer-content {
  color: #df3f68;
  font-weight: bold;
  font-size: 14px;
}

.final-cost-container {
  padding: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.verification-code {
  color: #001f69;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}

.validate-payment-button {
  display: block;
  margin: 15px auto 0;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.validate-payment-button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}
