.container-fluid-signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../img/newssabg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.logologin {
  width: 350px;
  margin-bottom: 5px;
}

.loginHolder {
  background-color: #4c4f63;
  padding: 40px;
  min-width: 450px;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.text-white {
  color: #ffffff;
}

.text-center {
  text-align: center;
}

.h6 {
  font-size: 1rem;
  margin-bottom: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.form__group {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  width: 100%;
  border: 0;
  background: #b4b5bd;
  outline: 0;
  font-size: 1rem;
  color: #000;
  padding: 15px;
  transition: background-color 0.2s, border-color 0.2s, padding-left 0.2s;
  border-radius: 40px;
}

.form__field::placeholder {
  color: rgba(0, 0, 0, 0.7);
  transition: padding-left 0.2s;
}

.form__field:focus::placeholder {
  padding-left: 20px;
  color: #c24269;
}

.btn-login {
  background-color: #c24269;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 100%;
}

.btn-login:hover {
  background-color: #26c9ff;
}

.lds-dual-ring1 {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.lds-dual-ring1:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring1 1.2s linear infinite;
}

@keyframes lds-dual-ring1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .container-fluid-signin {
    background-size: cover;
  }
}

@media (max-width: 425px) {
  .container-fluid-signin {
    background-size: cover;
    padding: 0 20px;
  }

  .logologin {
    width: 250px;
    margin-bottom: 10px;
  }

  .loginHolder {
    padding: 20px;
    min-width: 300px;
    border-radius: 15px;
  }

  .form__field {
    font-size: 0.9rem;
    padding: 12px;
    border-radius: 30px;
  }

  .btn-login {
    font-size: 0.9rem;
    padding: 12px;
    border-radius: 30px;
  }

  .h6 {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 375px) {
  .logologin {
    width: 200px;
    margin-bottom: 10px;
  }

  .loginHolder {
    padding: 15px;
    min-width: 280px;
    border-radius: 10px;
  }

  .form__field {
    font-size: 0.85rem;
    padding: 10px;
    border-radius: 25px;
  }

  .btn-login {
    font-size: 0.85rem;
    padding: 10px;
    border-radius: 25px;
  }

  .h6 {
    font-size: 0.85rem;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .logologin {
    width: 180px;
    margin-bottom: 8px;
  }

  .loginHolder {
    padding: 10px;
    min-width: 260px;
    border-radius: 10px;
  }

  .form__field {
    font-size: 0.8rem;
    padding: 8px;
    border-radius: 20px;
  }

  .btn-login {
    font-size: 0.8rem;
    padding: 8px;
    border-radius: 20px;
  }

  .h6 {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }
}

@media (max-width: 280px) {
  .logologin {
    width: 160px;
    margin-bottom: 5px;
  }

  .loginHolder {
    padding: 8px;
    min-width: 240px;
    border-radius: 10px;
  }

  .form__field {
    font-size: 0.75rem;
    padding: 6px;
    border-radius: 15px;
  }

  .btn-login {
    font-size: 0.75rem;
    padding: 6px;
    border-radius: 15px;
  }

  .h6 {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
}
