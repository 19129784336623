.notice-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 750px; /* Maximum height for each notice item */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Style the scrollbar track */
.notice-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Style the scrollbar thumb */
.notice-container::-webkit-scrollbar-thumb {
  background-color: #007bff; /* Blue thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #f1f1f1; /* Creates a little space between track and thumb */
}

/* Style the scrollbar itself (width, etc.) */
.notice-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Optional: Style for hover effect on the scrollbar thumb */
.notice-container::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3; /* Darker shade of blue on hover */
}

.notice-item {
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden; /* Keep this to contain the ::before element */
  margin-bottom: 25px;
}

.notice-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff; /* Adjust color as needed */
}

.icon-button:hover {
  color: #0056b3; /* Darker shade for hover */
}

.notice-item::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-color: #007bff;
  transform: rotate(45deg);
  transition: all 0.5s ease;
  z-index: 0;
  opacity: 0;
}

.notice-item:hover::before {
  top: -30%;
  left: -30%;
  opacity: 0.1;
}

.notice-item:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
}

.notice-title {
  color: #343a40;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 1;
}

.notice-content {
  color: #343a40;
  margin-bottom: 15px;
  z-index: 1;
}

.notice-date {
  color: #6c757d;
  font-size: 14px;
  z-index: 1;
}

.notice-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.notice-filter-input {
  padding: 10px 15px;
  border: 2px solid #e0e0e0;
  border-radius: 20px; /* Rounded edges for a modern look */
  width: 70%;
  font-size: 16px; /* Larger font size for better readability */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle inner shadow */
  transition: border-color 0.3s ease;
}

.notice-filter-input:focus {
  outline: none;
  border-color: #007bff; /* Highlight color on focus */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1),
    0 0 8px rgba(50, 150, 255, 0.6);
}

.add-notice-button {
  padding: 10px 20px;
  background-color: #28a745; /* Green color for the button */
  color: white;
  border: none;
  border-radius: 20px; /* Rounded edges */
  cursor: pointer;
  font-size: 16px; /* Larger font size */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-notice-button:hover {
  background-color: #218838; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}
