.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 500px;
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  outline: none;
}

.modal-header {
  margin-bottom: 25px;
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
}

.custom-textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  font-size: 1rem;
  transition: border 0.3s, box-shadow 0.3s;
}

.custom-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.send-button {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: 500;
}

.send-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.send-button:active {
  transform: translateY(1px);
}

.send-button + .send-button {
  margin-top: 20px;
  background-color: #28a745; /* Different color for the second button */
}

.send-button + .send-button:hover {
  background-color: #218838;
}
