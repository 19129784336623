.channel-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: auto;
}

.channel-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.verification-code {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.channel-services {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.service {
  background-color: #f2f2f2;
  color: #333;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
