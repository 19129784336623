.retune-chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
  z-index: 1500; /* Ensure it's on top of other components */
  transition: transform 0.3s ease-in-out;
  width: 400px; /* Increased width */
  height: 500px; /* Increased height */
}

.chat-close-button {
  position: absolute;
  top: 5px;
  left: 0px; /* Move it outside the chat widget */
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.chat-close-button:hover {
  color: #555;
}
