.posticket-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;
}

.info-container {
  width: 30%;
  background-color: #ffffff;
}

.iframe-container {
  padding: 20px;
  width: 63%;
  min-height: 95%;
}

.title-ticket {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ffffff;
  background-color: #092a51;
  padding: 25px;
  border-radius: 10px 10px 0 0;
  text-align: center;
}

.instruction {
  padding: 20px;
}

.instruction-title {
  font-size: 16px;
  color: #dd2c54;
  margin-bottom: 15px;
}

.instruction-text {
  font-size: 14px;
  color: #666666;
}

.instruction-text::before {
  content: "- ";
  color: #666666;
}

.centered-hr {
  display: flex;
  justify-content: center;
}

hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  width: 80%; /* Reduce the width of the hr */
  margin: 0;
}

@media (max-width: 1024px) {
  .posticket-container {
    display: flex;
    flex-direction: column; /* Change the layout to column */
    height: auto; /* Adjust height to auto for better vertical scrolling */
    padding: 20px; /* Add padding for better spacing */
  }

  .info-container {
    width: 100%; /* Full width for the info section */
    margin-bottom: 20px; /* Add space between info and iframe */
  }

  .iframe-container {
    width: 100%; /* Full width for iframe */
    min-height: 50vh; /* Adjust height to ensure it fits well on smaller screens */
  }

  .title-ticket {
    font-size: 20px; /* Adjust title size */
    padding: 20px; /* Reduce padding */
  }

  .instruction-title {
    font-size: 14px; /* Adjust title size */
  }

  .instruction-text {
    font-size: 12px; /* Adjust text size */
  }
}
