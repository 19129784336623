.billingv2-table-billings {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.billingv2-billing-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for a card-like appearance */
  border-radius: 10px; /* Rounding the corners */
  overflow: hidden; /* Ensures rounded corners are applied */
}

.billingv2-billing-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.billingv2-billing-table th {
  background-color: #092a51;
  color: #fff;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-top: 2px solid #092a51;
  border-bottom: 2px solid #092a51;
}

.billingv2-billing-table th:first-child {
  border-top-left-radius: 10px;
}

.billingv2-billing-table th:last-child {
  border-top-right-radius: 10px;
}

.billingv2-billing-table tbody {
  text-align: start;
  display: block;
  max-height: 120px;
  overflow-y: auto;
}

.billingv2-billing-table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.billingv2-billing-table td {
  padding: 8px;
  text-align: center;
  background-color: #fff;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
}

.billingv2-billing-table tr:last-child td {
  border-bottom: none;
}

.billingv2-status-active {
  color: green;
  font-weight: bold;
}

.billingv2-action-btn {
  background-color: #00a8ff;
  color: #fff;
  padding: 5px 8px;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.billingv2-billing-table td:first-child,
.billingv2-billing-table td:last-child {
  border-right: none;
}

.billingv2-skeleton-row .billingv2-skeleton {
  width: 100%;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
  margin: 8px 0;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
