/* Parent Container Styles */
.bot-details-parent {
  max-height: 700px; /* Maximum height of the parent container */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the child containers */
}

/* Individual Bot Container Styles */
.bot-details-container {
  background-color: #ffffff; /* White background */
  padding: 20px;
  border-radius: 15px; /* Softer rounded corners */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 90%; /* Adjusted width */
  margin: 10px 0; /* Spacing between each container */
  font-family: "Arial", sans-serif; /* Modern font */
}

/* Details Grid */
.bot-details-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Individual Detail Styles */
.bot-detail {
  display: flex;
  align-items: center;
  font-size: 1em;
  width: 45%; /* Adjust width for spacing */
  margin-bottom: 15px;
  color: #555555; /* Slightly lighter font color */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

/* Icon Styling */
.faRobot,
.faIdBadge,
.faIndustry,
.faMicrophoneAlt,
.faInfinity,
.faNetworkWired {
  margin-right: 10px;
  color: #4a90e2; /* A soft blue for icons */
}

/* Button Styling */
.buttoncard {
  background-color: #4a90e2; /* A vibrant blue color */
  color: white; /* White text for contrast */
  font-size: 1em;
  font-family: "Arial", sans-serif; /* Consistent font with the rest of the design */
  padding: 10px 20px; /* Adequate padding for a better look and feel */
  border: none; /* No border for a clean look */
  border-radius: 5px; /* Slightly rounded corners */
  cursor: pointer; /* Cursor change to indicate it's clickable */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions for hover effects */
  margin-top: 20px; /* Spacing from the details grid */
  align-self: center; /* Center align if within a flex container */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

/* Additional Responsive Adjustments for the .buttoncard */
@media (max-width: 768px) {
  .buttoncard {
    width: 100%; /* Full width on smaller screens */
    padding: 15px 0; /* Adjust padding for better touch accessibility */
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .bot-detail {
    width: 100%; /* Full width on smaller screens */
  }

  .bot-header {
    flex-direction: column; /* Stack header items on smaller screens */
    text-align: center;
    margin-bottom: 15px;
  }

  .bot-details-grid {
    justify-content: center;
  }
}
