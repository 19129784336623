.header_small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  width: 100%;
  margin-top: 15px;
  padding-right: 30px;
}

.header_left {
  flex: 0 1 20%;
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
}

.header_center {
  flex: 0 1 50%;
  display: flex;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}

.header_right {
  flex: 0 1 18%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding-left: 40px;
}

.header_left h1 {
  font-size: 36px;
  line-height: 34px;
  color: #000040;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.header_center .search_input_wrapper {
  position: relative;
  width: 650px;
  height: 60px;
  filter: drop-shadow(0 0 3.5px rgba(0, 0, 0, 0.06));
}

.header_center .search_input {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  border-radius: 12px;
  background-color: #ffffff;
  border: none;
  outline: none;
  font-size: 16px;
  color: #6c757d;
}

.header_center .search_input::placeholder {
  color: #adb5bd;
}

.header_center .search_icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 22px;
  color: #adb5bd;
}

.header_right {
  flex: 0 1 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-left: 15px;
}

.notification_bell_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_bell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: none;
  border: none;
  position: relative;
  transition: transform 0.2s ease;
  margin-right: 20px;
}

.notification_bell:hover {
  animation: ring 0.5s ease;
}

.notification_bell:active {
  transform: scale(0.9);
}

.notification_bell .bell_icon {
  font-size: 28px;
  color: #000040;
}

.notification_badge {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 10px;
  height: 10px;
  background-color: #e63946;
  border-radius: 50%;
  border: 2px solid white;
}

.user_details {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.user_avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.user_name_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.user_name {
  font-size: 21px;
  color: #2a242e;
  font-weight: 600;
  font-family: "Montserrat";
  margin: 0;
  margin-left: 8px;
}

.custom_dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  width: 200px;
  display: flex;
  flex-direction: column;
}

.custom_dropdown div,
.custom_dropdown button {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  background-color: transparent;
  border: none;
  text-align: left;
  width: 100%;
}

.custom_dropdown div:hover,
.custom_dropdown button:hover {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.custom_dropdown button {
  width: 100%;
  text-align: left;
}

.suggestions_dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion_item {
  padding: 10px 15px;
  cursor: pointer;
  color: #333;
  font-size: 16px;
}

.suggestion_item:hover {
  background-color: #f1f1f1;
}

.notifcontainer {
  display: flex;
  justify-content: space-evenly;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.notification_bell:hover .bell_icon {
  transform: scale(1.1);
}
