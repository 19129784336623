@media screen and (max-width: 1024px) {
  .bot-container {
    flex-direction: column;
  }

  .bot-sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .bot-chat {
    width: 100%;
    max-height: 600px;
    min-height: 600px;
  }
}
