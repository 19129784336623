.billingvechannel-container {
  padding: 20px;
  font-family: "Poppins", sans-serif;
  color: #333;
  max-width: 800px; /* Set a maximum width */
  margin: 0 auto; /* Center the container */
}

.billingvechannel-header {
  text-align: center;
  margin-bottom: 20px;
}

.billingvechannel-title {
  font-size: 1.5em;
  color: #005f73;
  margin-bottom: 10px;
}

.billingvechannel-description {
  color: #333;
  font-size: 1em;
}

.billingvechannel-cost-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  margin-bottom: 20px;
}

.billingvechannel-cost-detail {
  margin: 10px;
}

.billingvechannel-cost-title {
  display: block;
  font-weight: bold;
  color: #005f73;
  margin-bottom: 5px;
}

.billingvechannel-cost-value {
  font-size: 1.2em;
  color: #28a745;
}

.billingvechannel-benefits {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.billingvechannel-benefits-title {
  color: #005f73;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.billingvechannel-benefits-list {
  list-style-type: disc;
  list-style-position: inside;
  color: #333;
}

.billingvechannel-footer {
  color: #333;
  font-size: 0.9em;
  text-align: justify;
}
