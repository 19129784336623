/* ThankYouComponent.css */

.thank-you-container {
  text-align: center;
  padding: 50px;
  background-color: #f3f4f6; /* Light grey background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
  max-width: 600px;
  margin: 50px auto; /* Center the container */
}

.thank-you-title {
  color: #333; /* Dark text for contrast */
  margin-bottom: 20px;
}

.thank-you-text {
  color: #555; /* Slightly lighter text color */
  margin-bottom: 30px;
}

.thank-you-button {
  background-color: #4caf50; /* Green background */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px; /* Space out the buttons */
  transition: background-color 0.3s;
}

.thank-you-button:hover {
  background-color: #45a049; /* Darker green on hover */
}
