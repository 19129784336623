.VA-container {
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap to the next line */
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  padding: 20px;
  max-height: 80vh; /* Set the maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}

.VA-bot-card {
  flex: 0 0 calc(20% - 30px); /* Reduce width to 25% of the container */
  margin: 20px; /* Margin between cards */
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.VA-inner-rounded {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 40px;
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.VA-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  min-height: 135px;
}

.VA-header-left,
.VA-header-right {
  display: flex;
  align-items: flex-start;
}

.VA-back-icon {
  cursor: pointer;
  color: white;
  font-size: 42px;
}

.VA-menu-icon {
  font-size: 42px;
  color: white;
  cursor: pointer;
}

.VA-bot-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 20px;
  background-color: rgb(255, 255, 255);
}

.VA-bot-image {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  background-color: #002060;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.VA-bot-content h4 {
  font-size: 24px;
  margin: 10px 0;
  color: #00aaff;
}

.VA-bot-content p {
  font-size: 16px;
  color: #888;
  margin: 5px 0;
}

.VA-bot-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.VA-agent-info {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  color: #00aaff;
}

.VA-agent-info p {
  margin: 5px 0;
  font-size: 16px;
}

.VA-talk-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.VA-icon {
  font-size: 64px;
}

/* Blue Theme */
.blue-theme .VA-header {
  background-color: #00aaff;
}

.blue-theme .VA-bot-content h4 {
  color: #00aaff;
}

.blue-theme .VA-agent-info {
  color: #00aaff;
}

.blue-theme .VA-icon {
  color: #00aaff;
  font-size: 64px;
}

/* Red Theme */
.red-theme .VA-header {
  background-color: #ff0040;
}

.red-theme .VA-bot-content h4 {
  color: #ff0040;
}

.red-theme .VA-agent-info {
  color: #ff0040;
}

.red-theme .VA-icon {
  color: #ff0040;
  font-size: 64px;
}
