.plan-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  color: #333;
}

.plan-title {
  font-size: 1.6em;
  color: #005f73;
  margin-bottom: 10px;
  text-align: center;
}

.plan-description {
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

.plan-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

.detail-item {
  background-color: #f4f7f6;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.detail-title {
  font-size: 1.2em;
  color: #007bff;
  margin-bottom: 5px;
}

.detail-info {
  font-size: 1em;
  color: #666;
}

.total-cost {
  grid-column: 1 / -1; /* Make it span the full width */
  border: 2px solid #007bff;
  background-color: #e6f4ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.total-cost .detail-title {
  text-align: center;
  color: #005f73;
}

.total-cost .detail-info {
  text-align: center;
  font-size: 1.1em;
  color: #333;
}
