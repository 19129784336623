/* Additional CSS for AnnouncementModal */
.modal-tab-button {
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.modal-tab-button.active {
  background-color: #d0e0ff;
  color: #0056b3;
  font-weight: bold;
}

/* Styling for the close button */
.close-button {
  margin-top: auto;
  background-color: #ffcccc;
  color: #d33;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.close-button:hover {
  background-color: #e6b3b3;
}

/* Styling for the modal content */
#modal-description {
  color: #333;
  line-height: 1.5;
}

/* Styling for the modal title */
#modal-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
}

.checkbox-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px; /* Adds space between the checkbox and the text */
}

.checkbox-container input {
  margin: 0;
  padding: 0;
}

@keyframes modalOpenAnimation {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalCloseAnimation {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20%);
  }
}
