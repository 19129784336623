.maincard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout-card-container {
  width: 80%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-height: 500px;
}

.checkout-card-header {
  font-size: 42px;
  font-weight: bold;
  color: #001f69;
  text-align: center;
  margin-bottom: 20px;
}

.checkout-card-body {
  flex-grow: 1;
  margin-bottom: 20px;
  max-height: 500px;
  overflow-y: auto;
}

.checkout-bot-selection {
  background-color: #00bfff;
  border-radius: 15px;
  margin-bottom: 10px;
}

.checkout-bot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.checkout-bot-input-label {
  display: flex;
}

.checkout-bot-checkbox {
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid white;
  background-color: transparent;
}

.checkout-bot-checkbox:checked {
  background-color: white;
}

.checkout-bot-checkbox:checked::after {
  content: "✔️";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: red;
}

.checkout-bot-checkbox:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.checkout-bot-label {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  line-height: 1;
}

.checkout-view-details-icon {
  color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
  flex-shrink: 0;
}

.checkout-bot-details {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  color: #000000;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.checkout-bot-detail-row {
  display: flex;
  flex-direction: row;
}

.checkout-bot-detail-labels,
.checkout-bot-detail-values {
  flex: 1;
}

.checkout-detail-label {
  font-weight: bold;
  color: #df3f68;
  margin-bottom: 5px;
}

.checkout-detail-text {
  color: #333333;
  margin-bottom: 5px;
}

.checkout-card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout-total-cost {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333;
}

.checkout-cost-amount {
  color: #df3f68;
  font-size: 24px;
  font-weight: bold;
}

.checkout-button {
  width: 100%;
  padding: 15px 0;
  background-color: #00bfff;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #009ace;
}
